<template>
  <div class="dialog">
    <p class="title">
      生成した画像をダウンロードしますか？
      <span v-if="canPurchaseDownloadCredit"><br>追加料金 ¥11,000（税込）が発生します。</span>
    </p>

    <NuxtImg
      class="img"
      :src="downloadImageInfo?.src"
      alt="ダウンロード予定の画像"
    />

    <div
      v-if="!isProfessionalPlan"
      class="message-wrapper"
    >
      <div
        v-if="isStandardPlan"
        class="download-usage"
      >
        <p class="info">
          無料ダウンロード回数(月間)
        </p>
        <p class="count">
          {{ user?.monthlyFreeDownloadCount }}/{{ userPlan?.downloadLimit }}
        </p>
      </div>
      <p
        v-if="!isStandardPlan && !isProfessionalPlan && !downloadImageInfo?.isDownloaded"
        class="caution"
      >
        現在ご利用中のプランでは、無料ダウンロード枠が付与されていません。
      </p>
      <span
        v-if="downloadImageInfo?.isDownloaded"
        class="caution"
      >再ダウンロードの場合はダウンロード回数を消費しません。</span>
    </div>

    <div class="actions">
      <button
        class="btn -cancel"
        @click="closeDialog(id)"
      >
        戻る
      </button>
      <button
        class="btn -download"
        :disabled="isProcessing"
        @click="handleDownload"
      >
        <span v-if="canPurchaseDownloadCredit">有料で</span>ダウンロード
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import _ from 'lodash'
import { getPlanById, planEnum } from '@/constants/plan'
import { isProfessionalPlan as isProfessionalPlanFn, isStandardPlan as isStandardPlanFn } from '~/utils/plan'

const { id } = defineProps({
  id: {
    type: String,
    required: true,
  },
})

const isProcessing = ref(false)

const accountStore = useAccountStore()
const user = computed(() => accountStore.user)
const userPlan = computed(() => getPlanById(user.value!.planId))
const isStandardPlan = isStandardPlanFn(userPlan.value.id)
const isProfessionalPlan = isProfessionalPlanFn(userPlan.value.id)

const dialogStore = useDialogStore()
const { closeDialog, downloadImageInfo } = dialogStore

const artifactStore = useArtifactStore()
const { downloadArtifact, purchaseAndArtifactDownload, getArtifactWithTags } = artifactStore

const canPurchaseDownloadCredit = computed(() => {
  if (_.isUndefined(user.value) || downloadImageInfo?.isDownloaded) {
    return false
  }

  const { monthlyFreeDownloadCount, salesDownloadLimit = 0 } = user.value

  if (salesDownloadLimit > 0) {
    return false
  }

  switch (userPlan.value.id) {
    case planEnum.trial :
    case planEnum.lite :
      return true
    case planEnum.standard : {
      if (monthlyFreeDownloadCount < userPlan.value.downloadLimit) {
        return false
      } else {
        return true
      }
    }
    case planEnum.professional :
    default :
      return false
  }
})

const canDownload = computed(() => {
  if (_.isUndefined(user.value)) {
    return false
  }

  const { monthlyFreeDownloadCount, salesDownloadLimit = 0 } = user.value

  if (salesDownloadLimit > 0) {
    return true
  }

  switch (userPlan.value.id) {
    case planEnum.trial :
    case planEnum.lite :
      return false
    case planEnum.standard : {
      if (monthlyFreeDownloadCount < userPlan.value.downloadLimit) {
        return true
      } else {
        return false
      }
    }
    case planEnum.professional :
      return true
    default :
      return false
  }
})

async function handleDownload() {
  if (isProcessing.value || _.isUndefined(downloadImageInfo)) {
    return
  }

  const { isDownloaded, src, id: artifactId } = downloadImageInfo

  if (isDownloaded) {
    isProcessing.value = true
    downloadImage(src)
  } else {
    isProcessing.value = true
    if (canDownload.value) {
      await downloadArtifact(artifactId)
    } else if (canPurchaseDownloadCredit.value) {
      await purchaseAndArtifactDownload(artifactId)
    }
  }

  await getArtifactWithTags(artifactId)

  isProcessing.value = false
  closeDialog(id)
}
</script>

<style lang="scss" scoped>
.dialog {
  display: grid;
  grid-template-rows: fit-content(100%) minmax(0, auto) 1fr;
  gap: 1.5rem;
  padding: 1.5rem;
  max-width: 442px;
  max-height: 90%;
  border-radius: 0.5rem;
  background-color: rgba(var(--v-theme-background));

  > .img {
    max-width: 100%;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    height: fit-content;
    justify-self: center;
  }

  > .message-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  > .actions {
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
  }
}

.dialog > .message-wrapper {
  > .download-usage {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .info {
      font-size: 1rem;
    }
  }

  > .caution {
    font-size: 0.875rem;
  }
}

.dialog > .actions {
  > .btn {
    width: 50%;
    padding: 1.25rem 0;
    border-radius: 0.25rem;

    &.-cancel {
      border: 1px solid #fff;
    }

    &.-download {
      background-color: rgba(var(--v-theme-primary-light));
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.3;
    }
  }
}
</style>
